var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-modal',{attrs:{"name":_vm.id,"variant":"2xl","hideCloseButton":true,"disableBodyScroll":true,"escToClose":false,"clickToClose":false}},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.$t( 'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.headline' ))+" ")]),_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleAddDoc())}}},[_c('AppInput',{class:_vm.isSelectCardType ? '' : 'mb-32',attrs:{"type":"richselect","rules":"required","name":"type","label":_vm.$t(
              'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardType'
            ),"placeholder":"\n            Select\n          ","options":_vm.cardTypeChoices,"hide-search-box":""},model:{value:(_vm.formData.card_type),callback:function ($$v) {_vm.$set(_vm.formData, "card_type", $$v)},expression:"formData.card_type"}}),(_vm.isSelectCardType)?[_c('AppInput',{attrs:{"rules":"required","label":_vm.getCardIdTitle,"type":"text","name":_vm.getCardIdTitle},model:{value:(_vm.formData.card_id),callback:function ($$v) {_vm.$set(_vm.formData, "card_id", $$v)},expression:"formData.card_id"}}),(_vm.isDrivingCard)?[_c('AppInput',{attrs:{"rules":_vm.isIssueDateRequired ? 'required' : '',"label":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.issueDate'
                ),"type":"date","name":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.issueDate'
                )},model:{value:(_vm.formData.issue_date),callback:function ($$v) {_vm.$set(_vm.formData, "issue_date", $$v)},expression:"formData.issue_date"}}),_c('AppInput',{attrs:{"rules":_vm.isExpirationDateRequired ? 'required' : '',"label":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.expirationDate'
                ),"type":"date","name":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.expirationDate'
                )},model:{value:(_vm.formData.expiration_date),callback:function ($$v) {_vm.$set(_vm.formData, "expiration_date", $$v)},expression:"formData.expiration_date"}}),_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 gap-4"},[_c('div',[_c('ValidationProvider',{staticClass:"mt-2",attrs:{"tag":"div","name":_vm.$t(
                      'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardFrontPhoto.title'
                    ),"rules":_vm.isFrontPicRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":((_vm.$t(
                        'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardFrontPhoto.title'
                      )) + " " + (_vm.isFrontPicRequired ? '*' : ''))}}),_c('UploadImageAlt',{staticClass:"max-h-14",attrs:{"current":_vm.formData.card_pic,"text":_vm.$t(
                        'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardFrontPhoto.description'
                      ),"button-text":_vm.$t(
                        'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardFrontPhoto.buttonText'
                      ),"disabled":false},model:{value:(_vm.formData.card_pic),callback:function ($$v) {_vm.$set(_vm.formData, "card_pic", $$v)},expression:"formData.card_pic"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{staticClass:"mt-2",attrs:{"tag":"div","name":_vm.$t(
                      'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardBackPhoto.title'
                    ),"rules":_vm.isBackPicRequired ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":((_vm.$t(
                        'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardBackPhoto.title'
                      )) + " " + (_vm.isBackPicRequired ? '*' : ''))}}),_c('UploadImageAlt',{staticClass:"max-h-14",attrs:{"current":_vm.formData.card_pic_back,"text":_vm.$t(
                        'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardBackPhoto.description'
                      ),"button-text":_vm.$t(
                        'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardBackPhoto.buttonText'
                      ),"disabled":false},model:{value:(_vm.formData.card_pic_back),callback:function ($$v) {_vm.$set(_vm.formData, "card_pic_back", $$v)},expression:"formData.card_pic_back"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})],1)])]:[_c('ValidationProvider',{staticClass:"mt-2",attrs:{"tag":"div","name":_vm.$t(
                  'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardPhoto.title'
                ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('input-label',{attrs:{"error":errors[0],"text":((_vm.$t(
                    'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardPhoto.title'
                  )) + " *")}}),_c('UploadImageAlt',{staticClass:"max-h-14",attrs:{"current":_vm.formData.card_pic,"text":_vm.$t(
                    'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardPhoto.description'
                  ),"button-text":_vm.$t(
                    'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardPhoto.buttonText'
                  ),"disabled":false},model:{value:(_vm.formData.card_pic),callback:function ($$v) {_vm.$set(_vm.formData, "card_pic", $$v)},expression:"formData.card_pic"}}),_c('input-error-item',{attrs:{"message":errors[0]}})]}}],null,true)})]]:_vm._e(),_c('button',{ref:"addRiderDocSubmitButtonRef",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.save'))+" ")])],2)]}}])})],1),_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"},[_c('AppButton',{class:"w-1/2",attrs:{"variant":"secondary","disabled":_vm.isLoading},on:{"click":_vm.handleCancel}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","isLoading":_vm.isLoading},on:{"click":function($event){return _vm.$refs.addRiderDocSubmitButtonRef.click()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.save'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }