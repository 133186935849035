<template>
  <div class="wrapper">
    <div class="card">
      <div class="flex items-center w-full h-full">
        <div
          class="transform -rotate-90 referral-code"
          v-html="
            $t(
              'components.riderDetailsManagement.diveIntoTab.profile.grid.referralHealth.referral.referralCode'
            )
          "
        ></div>
        <div class="relative h-full border-r-2 border-dashed">
          <div class="circle-cut circle-top" />
          <div class="circle-cut circle-bottom" />
        </div>
        <div
          class="flex flex-col items-center justify-center w-full text-center"
        >
          <div class="code">
            <t-input
              class="text-center"
              :value="getRefCode()"
              ref="code"
              variant="untraceable"
              readonly="readonly"
            />
          </div>
          <div class="copy" @click="copyToClipBoard()">
            <i class="mr-1 far fa-copy"></i>
            {{
              $t(
                'components.riderDetailsManagement.diveIntoTab.profile.grid.referralHealth.referral.copyThisCode'
              )
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReferralCodeRibbon',
  props: ['text', 'code'],
  methods: {
    getRefCode() {
      return this.code ?? Math.floor(Math.random() * 1000 + 1)
    },
    copyToClipBoard() {
      this.$refs.code.select()
      this.$refs.code.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Code Copied',
          text: 'The referral code has been copied to your clipboard',
        },
        2000
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  min-width: 250px;
  height: 174px;
  //   margin: 20px 18px 1px 0;
  padding: 31px 29px;
  border-radius: 6px;
  background-color: #ede9ff;
  //   opacity: 0.18;
  //   background-image: linear-gradient(226deg, #9784ff 96%, #9668ff 22%);
}
.card {
  min-width: 200px;
  height: 122px;
  margin: auto auto;
  //   padding: 16px 53px 14px 67px;
  box-shadow: 0 4px 11px 0 rgba(49, 52, 105, 0.11);
  background-color: #ffffff;
}

.circle-cut {
  position: absolute;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #ede9ff;
  border-radius: 99999px;
}
.circle-cut.circle-top {
  left: -20px;
  top: -20px;
}
.circle-cut.circle-bottom {
  left: -20px;
  bottom: -20px;
  background-color: #ede9ff;
  background-image: linear-gradient(to bottom, #e7e2fc, #e7e2fe, #ede9ff);
}
.referral-code {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  text-transform: uppercase;
  color: #676773;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.code {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2e2e39;
}
.copy {
  margin-top: 10px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #da35a1;
  cursor: pointer;
}
.h-122px {
  height: 122px;
}
.w-122px {
  width: 118px;
}
.mt-31px {
  margin-top: 35px;
}
</style>
