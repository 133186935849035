<template>
  <button
    class="oto-edit-button focus:outline-none focus:shadow-none"
    @click="handleClicking"
  >
    <i class="fa-solid fa-plus"></i>
    <span class="ml-2 font-medium text-oBlack">
      {{ $t('components.stepNavigation.add') }}</span
    >
  </button>
</template>
<script>
export default {
  name: 'OtoAddButton',
  methods: {
    handleClicking() {
      this.$emit('click')
    },
  },
}
</script>
<style lang="sass" scoped>
$edit-btn-bg: #f3f3f3

.oto-edit-button
  display: flex
  align-items: center
  justify-content: center
  width: 96px
  height: 42px
  padding: 4px 23px 5px 12px
  border-radius: 30px
  background-color: $edit-btn-bg
  cursor: pointer
  &:hover
    background: lighten($edit-btn-bg, 2%)
    &:focus
      outline: none
      appearance: none
      shadow: none
</style>
