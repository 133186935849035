<template>
  <section>
    <div
      class="grid grid-cols-1 gap-0 px-4 py-3 my-3 border rounded-md border-oLightestGray md:grid-cols-2"
    >
      <div class="flex items-center">
        <photo-stack
          size="sm"
          :count="getDocsCount"
          :cover="getCoverImage"
          @click="showPopup()"
        />
        <div class="ml-4">
          <oto-typography variant="two" :text="title" />
          <br />
          <oto-typography variant="three" :text="subtitle" />
        </div>
      </div>
      <div class="flex items-center justify-between w-full">
        <div class="flex w-auto mt-2 md:items-center md:justify-center md:mt-0">
          <button
            @click="handleDownloading()"
            class="border-none rounded-md shadow-lg text-sm outline-none px-6 py-2"
          >
            <i class="fas fa-download text-gray-800 mr-3"></i>
            <span class="text-gray-800">{{
              $t(
                'components.riderDetailsManagement.diveIntoTab.profile.grid.documents.download'
              )
            }}</span>
          </button>
        </div>

        <div class="flex w-auto mt-2 md:items-center md:justify-end md:mt-0">
          <t-dropdown>
            <div
              slot="trigger"
              slot-scope="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
              }"
            >
              <button
                id="user-menu"
                class="flex uppercase items-center pr-6 pl-2 text-sm text-white transition duration-150 ease-in-out border-0 rounded-sm focus:outline-none focus:shadow-solid"
                :class="{
                  'bg-variant-green': currentStatusTitle === 'VERIFIED',
                  'bg-variant-red': currentStatusTitle === 'REJECTED',
                  'bg-gray-500': currentStatusTitle === 'PENDING',
                  'bg-yellow-500': currentStatusTitle === 'EXPIRED',
                }"
                aria-label="User menu"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                <i class="fas fa-chevron-down mr-2"></i>

                {{ currentStatusTitle }}
              </button>
            </div>

            <div slot-scope="{ blurHandler }" class="bg-white m-auto">
              <div class="w-29 absolute right-0 mt-2">
                <button
                  class="block font-semibold uppercase w-full px-4 py-2 text-sm text-left leading-5 text-variant-green transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem"
                  v-if="currentStatusTitle !== 'VERIFIED'"
                  @blur="blurHandler"
                  @click="handleVerification(true)"
                >
                  {{
                    $t(
                      'components.riderDetailsManagement.diveIntoTab.profile.grid.documents.verify'
                    )
                  }}
                </button>
                <button
                  class="block font-semibold uppercase w-full px-4 py-2 text-sm text-left leading-5 text-variant-red transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                  role="menuitem"
                  v-if="currentStatusTitle !== 'REJECTED'"
                  @blur="blurHandler"
                  @click="showRejectModal()"
                >
                  {{
                    $t(
                      'components.riderDetailsManagement.diveIntoTab.profile.grid.documents.reject'
                    )
                  }}
                </button>
              </div>
            </div>
          </t-dropdown>
        </div>
      </div>
    </div>
    <CoolLightBox
      effect="fade"
      :items="getImages"
      :index="image_viewing_index"
      :useZoomBar="true"
      @close="hidePopup()"
    />
    <t-modal
      :name="`rejectRiderDocument-${docId}`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="!isLoading"
      :clickToClose="!isLoading"
    >
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        {{
          $t(
            'components.riderDetailsManagement.diveIntoTab.profile.modals.rejectRiderDocument.headline'
          )
        }}
      </section>

      <div class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            class="reason-form"
            @submit.prevent="handleSubmit(handleVerification(false))"
            enctype="multipart/form-data"
          >
            <AppInput
              rules="required"
              type="richselect"
              name="reason"
              :label="
                $t(
                  'components.riderDetailsManagement.diveIntoTab.profile.modals.rejectRiderDocument.title.reason'
                )
              "
              :placeholder="
                $t(
                  'components.riderDetailsManagement.diveIntoTab.profile.modals.rejectRiderDocument.placeholder.reason'
                )
              "
              :options="rejectReasons"
              hide-search-box
              v-model="models.reason"
            />
            <AppInput
              v-model="models.remark"
              rules=""
              :label="
                $t(
                  'components.riderDetailsManagement.diveIntoTab.profile.modals.rejectRiderDocument.title.remark'
                )
              "
              type="textarea"
              name="remark"
            />
            <button type="submit" ref="rejectSubmitButtonRef" class="hidden">
              Save
            </button>
          </form>
        </ValidationObserver>
      </div>

      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton
          :variant="`secondary`"
          :class="`w-1/2`"
          :disabled="isLoading"
          @click="hideRejectModal()"
        >
          {{ $t('components.stepNavigation.cancel') }}
        </AppButton>

        <AppButton
          :variant="`primary`"
          :class="`w-1/2`"
          :disabled="isLoading"
          :isLoading="isLoading"
          @click="$refs.rejectSubmitButtonRef.click()"
        >
          {{
            $t(
              'components.riderDetailsManagement.diveIntoTab.profile.modals.rejectRiderDocument.action.reject'
            )
          }}
        </AppButton>
      </section>
    </t-modal>
  </section>
</template>

<script>
import PhotoStack from '@/components/cards/PhotoStack'
import OtoTypography from '@/components/ui/OtoTypography'
// import OtoDownloadButton from "@/components/ui/OtoDownloadButton.vue";
//import TDropdownItem from "@/components/dropdown/TDropdownItem";

import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'UserDocument',
  props: {
    docId: { type: [String, Number], required: true },
    cover: { type: String, required: false, default: '' },
    images: { type: Array, required: false },
    title: { type: String, required: false, default: '' },
    subtitle: { type: String, required: false, default: '' },
    status: { type: Boolean, required: false, default: false },
    statusTitle: { type: String, required: true },
  },
  components: {
    PhotoStack,
    OtoTypography,
    // OtoDownloadButton,
    //TDropdownItem,
    CoolLightBox,
  },
  data: function() {
    return {
      currentStatus: this.status,
      currentStatusTitle: this.statusTitle,
      isLoading: false,
      image_viewing_index: null,
      rejectReasons: [
        {
          text: 'Document is unreadable (dark or blurry document).',
          value: 'Document is unreadable (dark or blurry document).',
        },
        {
          text: 'The scan or picture is of bad quality.',
          value: 'The scan or picture is of bad quality.',
        },
        {
          text:
            'The submitted document doesn’t match with the rider in our system.',
          value:
            'The submitted document doesn’t match with the rider in our system.',
        },
        {
          text:
            'Identity on the document doesn’t match the data entered in the system.',
          value:
            'Identity on the document doesn’t match the data entered in the system.',
        },
        {
          text: 'The identity proof has reached its expiration date.',
          value: 'The identity proof has reached its expiration date.',
        },
        {
          text:
            'The document has been verified as falsified by our Security Team.',
          value:
            'The document has been verified as falsified by our Security Team.',
        },
      ],
      models: {
        reason: null,
        remark: '',
      },
    }
  },
  computed: {
    getStatusText() {
      return this.currentStatus ? 'Verified' : 'Unverified'
    },
    getStatusClass() {
      return this.currentStatus ? 'verified' : 'unverified'
    },
    getDocsCount() {
      return this.getImages.length
    },
    getCoverImage() {
      return this.cover
    },
    getImages() {
      return this.images.filter((item) => item.src !== null)
    },
  },
  methods: {
    showPopup: function() {
      this.image_viewing_index = 0
    },
    hidePopup: function() {
      this.image_viewing_index = null
    },
    handleVerification(isVerified) {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }
      if (!isVerified && !this.models.reason) {
        return
      }

      const self = this
      this.isLoading = true
      this.$emit(
        'verify',
        this.docId,
        isVerified,
        this.models.reason,
        this.models.remark,
        (status) => {
          self.isLoading = false

          if (typeof status !== 'undefined') {
            self.currentStatus = status
            self.currentStatusTitle = status ? 'VERIFIED' : 'REJECTED'
          }

          if (!status) {
            self.models.reason = null
            self.models.remark = ''
            self.hideRejectModal()
          }
        }
      )
    },
    handleDeclining() {
      this.$emit('decline', this.docId)
    },
    handleDownloading() {
      this.$emit('download', this.docId)
    },
    hideRejectModal() {
      this.models.reason = null
      this.models.remark = ''
      this.$modal.hide(`rejectRiderDocument-${this.docId}`)
    },
    showRejectModal() {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }
      this.$modal.show(`rejectRiderDocument-${this.docId}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.docs-dropdown-button {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 110px;
  height: 18px;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 500;
}
.verified {
  background-color: #09aa35;
}
.unverified {
  background-color: #f67606;
}
.bg-variant-green {
  background-color: #09aa35;
}
.bg-variant-red {
  background-color: #d90a20;
}
.text-variant-green {
  color: #09aa35;
}
.text-variant-red {
  color: #d90a20;
}
form.reason-form {
  min-height: 200px;
}
</style>
