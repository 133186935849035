<template>
  <div>
    <t-dropdown v-if="status === 'O'" variant="smActions">
      <template
        v-slot:trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler,
        }"
      >
        <button
          aria-label="On Rent Dropdown"
          class="vld-parent trip-status-pill-button purple focus:outline-none"
          style="height: 35px; width: 170px"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <loading
            :height="25"
            :opacity="0"
            color="#fff"
            v-if="isLoading"
            :active="true"
            loader="bars"
            :is-full-page="false"
          />
          <template v-else>
            <span>On Rent</span>
            <i class="fas fa-chevron-down" />
          </template>
        </button>
      </template>

      <template v-slot:default="{ hide }">
        <div @click="hide()" class="py-1 mt-1 bg-white rounded-md shadow-md">
          <t-dropdown-item @click="executeAction('complete')">
            End Trip
          </t-dropdown-item>

          <t-dropdown-item
            @click="executeAction('cancel')"
            class="text-oRed hover:bg-red-100"
          >
            Cancel Trip
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>

    <TripStatusPillButton v-else :status="status" />
  </div>
</template>

<script>
import { TripConfig } from '@/config/TripConfig'
import { getTripStatusText } from '@/utils/TripStatus'
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import TripStatusPillButton from '@/components/button/TripStatusPillButton.vue'

export default {
  name: 'TripCompletedDropdown',
  components: {
    TDropdownItem,
    TripStatusPillButton,
  },
  props: {
    tripId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      status: 'O',
    }
  },
  computed: {
    getStatusText() {
      return getTripStatusText(getTripStatusText)
    },
  },
  methods: {
    async executeAction(actionId) {
      let title = ''
      let message = ''
      let endpoint = ''

      if (actionId === 'complete') {
        title = 'Trip Ended'
        message = 'The Trip is marked as completed'
        endpoint = TripConfig.api.actions.complete(this.tripId)
      } else if (actionId === 'cancel') {
        title = 'Trip Cancelled'
        message = 'The trip is marked as cancelled'
        endpoint = TripConfig.api.actions.cancel(this.tripId)
      }

      this.isLoading = true
      await this.$http
        .post(endpoint)
        .then(() => {
          if (actionId === 'complete') {
            this.status = 'C'
          } else if (actionId === 'cancel') {
            this.status = 'A'
          }
          this.$store.dispatch('lastTrip/saveStatus', this.status)
          // alert(this.$store.getters["lastTrip/getStatus"]);

          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: title,
              text: message,
            },
            3000
          )
        })
        .catch((err) => {
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Error',
              text: `Action failed to execute [ERR:${err.response.status}]`,
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/trip-status.scss';
</style>
