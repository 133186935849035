var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-modal',{attrs:{"name":("editRider-" + _vm.primaryKey),"variant":"confirmation","hideCloseButton":true,"disableBodyScroll":true,"escToClose":true,"clickToClose":true}},[_c('section',{staticClass:"px-4 pt-4 text-2xl font-extrabold text-center"},[_vm._v(" "+_vm._s(_vm.$t( 'components.riderDetailsManagement.diveIntoTab.profile.modals.editRider.headline' ))+" ")]),_c('div',{staticClass:"px-5 pt-5"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.handleUpdate())}}},[(_vm.singleUpdateKey === 'phone_number')?_c('AppInput',{attrs:{"rules":"required","label":_vm.$t(
              'components.riderDetailsManagement.diveIntoTab.profile.modals.editRider.title.phoneNumber'
            ),"type":"text","name":_vm.$t(
              'components.riderDetailsManagement.diveIntoTab.profile.modals.editRider.title.phoneNumber'
            )},model:{value:(_vm.phone_number),callback:function ($$v) {_vm.phone_number=$$v},expression:"phone_number"}}):_vm._e(),(_vm.singleUpdateKey === 'email')?_c('AppInput',{attrs:{"rules":"required","label":_vm.$t(
              'components.riderDetailsManagement.diveIntoTab.profile.modals.editRider.title.email'
            ),"type":"email","name":_vm.$t(
              'components.riderDetailsManagement.diveIntoTab.profile.modals.editRider.title.email'
            )},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}):_vm._e(),_c('button',{ref:"editRiderSubmitButtonRef",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v(" Save ")])],1)]}}])})],1),_c('section',{staticClass:"flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"},[_c('AppButton',{class:"w-1/2",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.closeEditRiderModal()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.cancel'))+" ")]),_c('AppButton',{class:"w-1/2",attrs:{"variant":"primary","isLoading":_vm.isLoading},on:{"click":function($event){return _vm.$refs.editRiderSubmitButtonRef.click()}}},[_vm._v(" "+_vm._s(_vm.$t('components.stepNavigation.update'))+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }