<template>
  <t-modal
    :name="id"
    :variant="`2xl`"
    :hideCloseButton="true"
    :disableBodyScroll="true"
    :escToClose="false"
    :clickToClose="false"
  >
    <section class="px-4 pt-4 text-2xl font-extrabold text-center">
      {{
        $t(
          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.headline'
        )
      }}
    </section>

    <div class="px-5 pt-5">
      <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
        <form
          @submit.prevent="handleSubmit(handleAddDoc())"
          enctype="multipart/form-data"
        >
          <AppInput
            type="richselect"
            rules="required"
            :class="isSelectCardType ? '' : 'mb-32'"
            name="type"
            :label="
              $t(
                'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardType'
              )
            "
            placeholder="
              Select
            "
            :options="cardTypeChoices"
            v-model="formData.card_type"
            hide-search-box
          />

          <template v-if="isSelectCardType">
            <AppInput
              v-model="formData.card_id"
              rules="required"
              :label="getCardIdTitle"
              type="text"
              :name="getCardIdTitle"
            />

            <template v-if="isDrivingCard">
              <AppInput
                v-model="formData.issue_date"
                :rules="isIssueDateRequired ? 'required' : ''"
                :label="
                  $t(
                    'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.issueDate'
                  )
                "
                type="date"
                :name="
                  $t(
                    'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.issueDate'
                  )
                "
              />
              <AppInput
                v-model="formData.expiration_date"
                :rules="isExpirationDateRequired ? 'required' : ''"
                :label="
                  $t(
                    'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.expirationDate'
                  )
                "
                type="date"
                :name="
                  $t(
                    'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.expirationDate'
                  )
                "
              />

              <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <ValidationProvider
                    tag="div"
                    class="mt-2"
                    :name="
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardFrontPhoto.title'
                      )
                    "
                    :rules="isFrontPicRequired ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <input-label
                      :error="errors[0]"
                      :text="
                        `${$t(
                          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardFrontPhoto.title'
                        )} ${isFrontPicRequired ? '*' : ''}`
                      "
                    />

                    <UploadImageAlt
                      class="max-h-14"
                      :current="formData.card_pic"
                      :text="
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardFrontPhoto.description'
                        )
                      "
                      :button-text="
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardFrontPhoto.buttonText'
                        )
                      "
                      :disabled="false"
                      v-model="formData.card_pic"
                    />

                    <input-error-item :message="errors[0]" />
                  </ValidationProvider>
                </div>

                <div>
                  <ValidationProvider
                    tag="div"
                    class="mt-2"
                    :name="
                      $t(
                        'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardBackPhoto.title'
                      )
                    "
                    :rules="isBackPicRequired ? 'required' : ''"
                    v-slot="{ errors }"
                  >
                    <input-label
                      :error="errors[0]"
                      :text="
                        `${$t(
                          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardBackPhoto.title'
                        )} ${isBackPicRequired ? '*' : ''}`
                      "
                    />

                    <UploadImageAlt
                      class="max-h-14"
                      :current="formData.card_pic_back"
                      :text="
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardBackPhoto.description'
                        )
                      "
                      :button-text="
                        $t(
                          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardBackPhoto.buttonText'
                        )
                      "
                      :disabled="false"
                      v-model="formData.card_pic_back"
                    />

                    <input-error-item :message="errors[0]" />
                  </ValidationProvider>
                </div>
              </div>
            </template>
            <template v-else>
              <ValidationProvider
                tag="div"
                class="mt-2"
                :name="
                  $t(
                    'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardPhoto.title'
                  )
                "
                rules="required"
                v-slot="{ errors }"
              >
                <input-label
                  :error="errors[0]"
                  :text="
                    `${$t(
                      'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardPhoto.title'
                    )} *`
                  "
                />

                <UploadImageAlt
                  class="max-h-14"
                  :current="formData.card_pic"
                  :text="
                    $t(
                      'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardPhoto.description'
                    )
                  "
                  :button-text="
                    $t(
                      'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.cardPhoto.buttonText'
                    )
                  "
                  :disabled="false"
                  v-model="formData.card_pic"
                />

                <input-error-item :message="errors[0]" />
              </ValidationProvider>
            </template>
          </template>

          <button type="submit" ref="addRiderDocSubmitButtonRef" class="hidden">
            {{ $t('components.stepNavigation.save') }}
          </button>
        </form>
      </ValidationObserver>
    </div>

    <section
      class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
    >
      <AppButton
        :variant="`secondary`"
        :class="`w-1/2`"
        @click="handleCancel"
        :disabled="isLoading"
      >
        {{ $t('components.stepNavigation.cancel') }}
      </AppButton>

      <AppButton
        :variant="`primary`"
        :class="`w-1/2`"
        :isLoading="isLoading"
        @click="$refs.addRiderDocSubmitButtonRef.click()"
      >
        {{ $t('components.stepNavigation.save') }}
      </AppButton>
    </section>
  </t-modal>
</template>
<script>
import { useEndpoints } from '@/composables'
import { BasicSettingConfig } from '@/config/SettingsConfig'

export default {
  name: 'AddRiderDocument',
  components: {
    InputLabel: () => import('@/components/form/InputLabel.vue'),
    UploadImageAlt: () => import('@/components/form/UploadImageAlt'),
  },
  props: {
    id: {
      type: String,
      default: 'add-rider-document',
    },
  },
  data() {
    return {
      isLoading: false,
      cardTypeChoices: [
        { text: 'Driving License', value: 'D' },
        { text: 'Passport', value: 'P' },
        { text: 'National ID', value: 'N' },
        { text: 'Student ID', value: 'S' },
        { text: 'Official ID', value: 'O' },
      ],
      isFrontPicRequired: false,
      isBackPicRequired: false,
      isIssueDateRequired: false,
      isExpirationDateRequired: false,
      formData: {
        card_id: '',
        card_type: '',
        card_pic: null,
        card_pic_back: null,
        issue_date: '',
        expiration_date: '',
      },
    }
  },
  created() {
    this.$http.get(BasicSettingConfig.api.index(this.orgId)).then((res) => {
      this.isFrontPicRequired = res.data.driver_id_required
      this.isBackPicRequired = res.data.driver_id_back_pic_required
      this.isIssueDateRequired = res.data.driver_id_issue_date_required
      this.isExpirationDateRequired =
        res.data.driver_id_expiration_date_required
    })
  },
  computed: {
    orgId() {
      return this.$store.getters['auth/organizationInfo'].id || null
    },
    getRiderId() {
      return this.$route.params.id
    },
    getCardIdTitle() {
      if (this.formData.card_type === 'D') {
        return this.$t(
          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.drivingLicenseId'
        )
      }
      if (this.formData.card_type === 'P') {
        return this.$t(
          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.passportId'
        )
      }
      if (this.formData.card_type === 'N') {
        return this.$t(
          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.nationalId'
        )
      }
      if (this.formData.card_type === 'S') {
        return this.$t(
          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.studentId'
        )
      }
      if (this.formData.card_type === 'O') {
        return this.$t(
          'components.riderDetailsManagement.diveIntoTab.profile.modals.addRiderDoc.title.officialId'
        )
      }
      return 'Card Id'
    },
    isSelectCardType() {
      return !!this.formData.card_type
    },
    isDrivingCard() {
      return this.formData.card_type === 'D'
    },
  },
  methods: {
    handleCancel() {
      this.$modal.hide(this.id)
      this.formData = {
        card_id: '',
        card_type: '',
        card_pic: null,
        card_pic_back: null,
        issue_date: '',
        expiration_date: '',
      }
    },
    handleAddDoc() {
      console.log({ handleAddDoc: this.formData })
      this.isLoading = true
      const url = useEndpoints.rider.addDoc()
      const formDataProxy = {
        ...this.formData,
        issue_date: this.formData.issue_date
          .split('-')
          .reverse()
          .join('/'),
        expiration_date: this.formData.expiration_date
          .split('-')
          .reverse()
          .join('/'),
      }

      const data = new FormData()
      data.append('user', this.getRiderId)

      for (const key in formDataProxy) {
        if (formDataProxy[key]) data.append(key, formDataProxy[key])
      }

      this.$http
        .post(url, data)
        .then((res) => {
          console.log({ docRes: { res } })
          this.$emit('refresh')
          this.$notify(
            {
              group: 'bottomRight',
              type: 'success',
              title: 'Success',
              text: `${res.data.data || 'Upload Successfully'}`,
            },
            3000
          )
          this.handleCancel()
        })
        .catch((err) => {
          this.$notify({
            group: 'bottomRight',
            type: 'error',
            title: 'Failed',
            text: `${err.response.data.message || 'Failed'}`,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    reverseString(str) {
      return str === '' ? '' : this.reverseString(str.substr(1)) + str.charAt(0)
    },
  },
}
</script>
<style lang=""></style>
