<template>
  <div>
    <div class="grid">
      <div class="grid grid-cols-2 my-3">
        <div class="font-semibold text-14px text-oDark">
          {{
            $t(
              'components.riderDetailsManagement.diveIntoTab.profile.grid.basicInfo.infoTitle.profileStatus'
            )
          }}
        </div>
        <div class="font-medium text-gray-500 text-14px">
          <t-dropdown variant="smActionsLeft">
            <template
              v-slot:trigger="{
                mousedownHandler,
                focusHandler,
                blurHandler,
                keydownHandler,
              }"
            >
              <button
                class="profile-status-badge focus:outline-none"
                :class="currentStatus"
                aria-label="Profile Status"
                aria-haspopup="true"
                @mousedown="mousedownHandler"
                @focus="focusHandler"
                @blur="blurHandler"
                @keydown="keydownHandler"
              >
                {{ getCurrentStatusText }}
                <i class="mt-1 ml-1 fas fa-chevron-down" />
              </button>
            </template>
            <template v-slot:default="{ hide }">
              <div
                @click="hide()"
                class="py-1 mt-px bg-white rounded-md shadow-md"
              >
                <t-dropdown-item
                  v-if="currentStatus !== 'H'"
                  @click="showDialog('H')"
                >
                  {{ $t('components.riderManagement.action.hold') }}
                </t-dropdown-item>
                <t-dropdown-item
                  v-if="currentStatus !== 'A'"
                  @click="action('A')"
                >
                  {{ $t('components.riderManagement.action.activate') }}
                </t-dropdown-item>
                <t-dropdown-item
                  v-if="currentStatus !== 'D'"
                  @click="showDialog('D')"
                >
                  {{ $t('components.riderManagement.action.deactivate') }}
                </t-dropdown-item>
                <t-dropdown-item @click="actionForceSync()">
                  {{ $t('components.riderManagement.action.forceSyncStatus') }}
                </t-dropdown-item>
              </div>
            </template>
          </t-dropdown>
        </div>
      </div>
    </div>
    <t-modal
      :name="`holdOrDeactivateUserAccount`"
      :variant="`confirmation`"
      :hideCloseButton="true"
      :disableBodyScroll="true"
      :escToClose="!isLoading"
      :clickToClose="!isLoading"
    >
      <section class="px-4 pt-4 text-2xl font-extrabold text-center">
        {{
          $t(
            'components.riderManagement.modal.holdOrDeactivateUserAccount.headline',
            {
              title:
                pendingAction === 'D'
                  ? $t('components.riderManagement.action.deactivate')
                  : $t('components.riderManagement.action.hold'),
            }
          )
        }}
      </section>

      <div class="px-5 pt-5">
        <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
          <form
            @submit.prevent="handleSubmit(confirmDialog())"
            enctype="multipart/form-data"
          >
            <AppInput
              rules="required"
              type="richselect"
              name="reason"
              :label="
                $t(
                  'components.riderManagement.modal.holdOrDeactivateUserAccount.title.reason'
                )
              "
              :placeholder="
                $t(
                  'components.riderManagement.modal.holdOrDeactivateUserAccount.placeholder.reason'
                )
              "
              :options="
                pendingAction === 'H' ? reasons.hold : reasons.deactivate
              "
              hide-search-box
              v-model="models.reason"
            />
            <AppInput
              v-model="models.remark"
              rules=""
              :label="
                $t(
                  'components.riderManagement.modal.holdOrDeactivateUserAccount.title.remark'
                )
              "
              type="textarea"
              name="remark"
            />
            <button
              type="submit"
              ref="holdOrDeactivateSubmitButtonRef"
              class="hidden"
            >
              {{ $t('components.stepNavigation.save') }}
            </button>
          </form>
        </ValidationObserver>
      </div>

      <section
        class="flex items-center justify-end px-4 py-4 mt-6 space-x-5 bg-gray-50"
      >
        <AppButton
          :variant="`secondary`"
          :class="`w-1/2`"
          @click="hideDialog()"
        >
          {{ $t('components.stepNavigation.cancel') }}
        </AppButton>

        <AppButton
          :variant="`primary`"
          :class="`w-1/2`"
          :isLoading="isLoading"
          @click="$refs.holdOrDeactivateSubmitButtonRef.click()"
        >
          {{
            $t(
              'components.riderManagement.modal.holdOrDeactivateUserAccount.action',
              {
                action:
                  pendingAction === 'D'
                    ? $t('components.riderManagement.action.deactivate')
                    : $t('components.riderManagement.action.hold'),
              }
            )
          }}
        </AppButton>
      </section>
    </t-modal>
  </div>
</template>

<script>
import { OrganizationUserConfig } from '@/config/OrganizationUserConfig'
import TDropdownItem from '@/components/dropdown/TDropdownItem'
import { EventBus } from '@/utils'
import { useEndpoints } from '@/composables'

export default {
  name: 'ProfileStatusDropdown',
  components: {
    TDropdownItem,
  },
  props: {
    status: {
      type: String,
      required: true,
      validator: function(value) {
        return ['H', 'A', 'D'].indexOf(value) !== -1
      },
    },
    userId: {
      type: String,
      required: true,
    },
  },
  watch: {
    status(updated) {
      console.warn({ updated })
      this.currentStatus = updated
    },
  },
  data() {
    return {
      aliases: {
        H: 'On Hold',
        A: 'Active',
        D: 'Inactive',
      },
      isLoading: false,
      currentStatus: this.status,
      pendingAction: '',
      models: {
        reason: null,
        remark: '',
      },
      reasons: {
        deactivate: [
          {
            text: 'Damaged Vehicle',
            value: 'DAMAGED_VEHICLE',
          },
          {
            text: 'Violates Geofence Rules',
            value: 'GEOFENCE_RULE_VIOLATION',
          },
          {
            text: 'Payment Compliance',
            value: 'PAYMENT_COMPLIANCE',
          },
        ],
        hold: [
          {
            text: 'Suspicious Activity - OTP',
            value: 'SUSPICIOUS_ACTIVITY_OTP',
          },
          {
            text: 'Suspicious Activity - Vehicle',
            value: 'SUSPICIOUS_ACTIVITY_VEHICLE',
          },
          {
            text: 'Suspicious Activity - Transactions',
            value: 'SUSPICIOUS_ACTIVITY_TRANSACTIONS',
          },
          {
            text: 'Suspicious Activity - Trips',
            value: 'SUSPICIOUS_ACTIVITY_TRIPS',
          },
        ],
      },
    }
  },
  computed: {
    getCurrentStatusText() {
      return this.aliases[this.currentStatus]
    },
  },
  methods: {
    id() {
      return this.$route.params.id
    },
    async actionForceSync() {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }
      const url = useEndpoints.rider.status.forceSync(this.userId)
      await this.$http
        .post(url)
        .then((res) => {
          console.log('successful-force-sync-response', res)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Force Sync',
              text: res.data.detail,
            },
            3000
          )
          this.$emit('refresh')
        })
        .catch((err) => {
          console.log('unsuccessful-force-sync-response', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: err.response.data,
            },
            3000
          )
        })
    },
    async action(id) {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }
      this.isLoading = true
      const formData = new FormData()
      formData.append('user_status', id)

      if (id === 'D' || id === 'H') {
        formData.append('status_reason', this.models.reason)
        formData.append('status_remark', this.models.remark)
      }

      await this.$http
        .put(OrganizationUserConfig.api.updateStatus(this.userId), formData)
        .then((res) => {
          this.$notify(this.getToastData(res.data.user_status), 5000)
          this.currentStatus = res.data.user_status
          this.$store.dispatch('riderdetails/saveStatus', this.currentStatus)
          EventBus.$emit('rider-details-header-refresh')
          // console.warn({
          //   dd: this.$store.getters["riderdetails/getData"].user_status
          // });
        })
        .catch((err) => {
          console.warn(err)
          this.$notify(
            {
              group: 'generic',
              type: 'danger',
              title: 'Action Execution Failed!',
              text: `The action encountered an error from server. [ERR:${err.response.status}]`,
            },
            5000
          )
        })
        .finally(() => {
          this.isLoading = false
          this.hideDialog()
        })
    },
    getToastData(action) {
      let titles = {
        H: 'Account Hold',
        A: 'Account Activation',
        D: 'Account Deactivation',
      }
      const title = action in titles ? titles[action] : 'Account Status'

      let messages = {
        H: 'The rider account is put on hold',
        A: 'The rider account is activated',
        D: 'The rider account is deactivated',
      }
      const message =
        action in messages
          ? messages[action]
          : 'The rider account status is updated'

      return {
        group: 'generic',
        type: 'success',
        title: title,
        text: message,
      }
    },
    confirmDialog() {
      if (!this.models.reason) {
        return
      }

      this.action(this.pendingAction)
    },
    hideDialog() {
      this.models.reason = null
      this.models.remark = ''
      this.pendingAction = ''
      this.$modal.hide('holdOrDeactivateUserAccount')
    },
    showDialog(action) {
      if (this.$acl.canNotUpdate('riders')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'riders',
          })
        )
        return
      }

      this.pendingAction = action
      this.$modal.show('holdOrDeactivateUserAccount')
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-status-badge {
  width: 6rem;
  @apply rounded-full px-1 py-0;

  &.H {
    @apply bg-orange-200 text-oOrange;
  }
  &.A {
    @apply bg-green-200 text-oGreen;
  }
  &.D {
    @apply bg-red-200 text-oRed;
  }
}
</style>
